@import "~react-image-gallery/styles/css/image-gallery.css";

body,
html {
  font-family: "Open Sans", sans-serif;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  color: #777;
  font-weight: 400;
  width: 100% !important;
  height: 100% !important;
}

.wrapper {
  width: 100%;
}

h2,
h3,
h4 {
  font-family: "Raleway", sans-serif;
}

h2 {
  text-transform: uppercase;
  margin: 0 0 20px 0;
  font-weight: 800;
  font-size: 36px;
  color: #333;
}
h3 {
  font-size: 20px;
  font-weight: 600;
  color: #333;
}
h4 {
  font-size: 18px;
  color: #333;
  font-weight: 600;
}
h5 {
  text-transform: uppercase;
  font-weight: 700;
  line-height: 20px;
}
p {
  font-size: 15px;
  font-weight: 500;
}

p.intro {
  margin: 12px 0 0;
  line-height: 24px;
}
a {
  color: #608dfd;
  font-weight: 400;
}
a:hover,
a:focus {
  text-decoration: none;
  color: #608dfd;
}
hr {
  height: 2px;
  width: 70px;
  text-align: center;
  position: relative;
  background: #1e7a46;
  margin-bottom: 20px;
  border: 0;
}

.btn-primary {
  background-color: #600fbf;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:active:hover,
.btn-primary.active:hover,
.show > .btn-primary.dropdown-toggle,
.show > .btn-primary.dropdown-toggle:focus,
.show > .btn-primary.dropdown-toggle:hover {
  background-color: #8216a8;
}
.fab {
  color: #ffffff;
  font-size: xx-large;
}

.text-center {
  text-align: center;
  justify-content: center;
}

#menu {
  padding: 15px;
  transition: all 0.8s;
}

.nav-icon img {
  width: 35px;
  position: relative;
  top: -10px;
  right: -10px;
}
#menu.navbar-default {
  background-color: rgb(138, 222, 255);
  border-color: rgba(231, 231, 231, 0);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}
/* */
@import url("https://fonts.googleapis.com/css2?family=Pacifico&display=swap");

i.nav-icon {
  margin: 0 5px;
  color: #222222;
}

.nav-link {
  width: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-item .nav-link {
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  color: #131313;
}

.nav-item .nav-link:hover {
  color: #e74091 !important;
}

.nav-item #donate {
  background-color: #ffd11f;
}

.nav-item .nav-link svg {
  margin: 0 5px;
}
.navbar-nav .nav-link.btn {
  background: #e74091;
}

.navbar a:not(.btn):not(.dropdown-item) {
  color: #131313;
}
a.navbar-brand span.avian {
  color: #e74091;
}
a.navbar-brand span.for {
  color: #fbc02d;
}
a.navbar-brand span.india {
  color: #2986ff;
}
a.navbar-brand {
  font-family: "Pacifico", cursive;
  font-size: 1.5rem !important;
  font-weight: 500;
  text-transform: none !important;
}
#menu.navbar-default .navbar-nav > li > a {
  font-family: "Lato", sans-serif;
  text-transform: uppercase;
  color: #555;
  font-size: 15px;
  font-weight: 400;
  padding: 8px 2px;
  border-radius: 0;
  margin: 9px 20px 0 20px;
}
#menu.navbar-default .navbar-nav > li > a:after {
  display: block;
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 0;
  height: 2px;
  background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
  content: "";
  transition: width 0.2s;
}
#menu.navbar-default .navbar-nav > li > a:hover:after {
  width: 100%;
}
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
  background-color: transparent;
}
.navbar-default .navbar-nav > .active > a:after,
.navbar-default .navbar-nav > .active > a:hover:after,
.navbar-default .navbar-nav > .active > a:focus:after {
  display: block !important;
  position: absolute !important;
  left: 0 !important;
  bottom: -1px !important;
  width: 100% !important;
  height: 2px !important;
  background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%) !important;
  content: "" !important;
  transition: width 0.2s !important;
}
.navbar-toggle {
  border-radius: 0;
}
.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
  background-color: #fff;
  border-color: #608dfd;
}
.navbar-default .navbar-toggle:hover > .icon-bar {
  background-color: #608dfd;
}

.nav-bg-color {
  background-color: #ffffff;
}
.section-title {
  margin-bottom: 20px;
}
.section-title h2 {
  position: relative;
  margin-top: 10px;
  margin-bottom: 15px;
  padding-bottom: 15px;
  color: #e74091;
}
.pageheader-landing {
  position: relative;
}
.pageheader-bg {
  position: relative;
}
.pageheader-overlay {
  background: linear-gradient(180deg, #009688cf, transparent);
  color: black;
  min-height: 60vh;
  position: absolute;
  width: 100%;
  z-index: 11;
  opacity: 0.3;
}
.pageheader-content {
  position: absolute;
  padding: 10rem 0.5rem 7rem 0.5rem;
  width: 100%;
  text-align: center;
  z-index: 20;
}
.pageheader-content h2 {
  color: #fffffd;
  text-shadow: 0px 2px 11px black;
  font-size: 5rem;
}
@media screen and (max-width: 1920px) {
  .pageheader-img {
    background-image: url("../img/header-bg.jpg");
    background-position: center;
    background-size: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .pageheader-award-img {
    background-image: url("../img/11.jpg");
    background-size: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .pageheader-landing {
    height: 45vh;
  }
  .pageheader-bg {
    height: 45vh;
  }
}
@media screen and (max-width: 418px) {
  .pageheader-img {
    background-image: url("../img/mem-bg-mb.jpg");
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .pageheader-award-img {
    background-image: url("../img/12.jpg");
    background-size: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .pageheader-landing {
    height: 60vh;
  }
  .pageheader-bg {
    height: 60vh;
  }
  .pageheader-content h2 {
    font-size: 3rem;
  }
}

.section-title h2::after {
  position: absolute;
  content: "";
  background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
  height: 4px;
  width: 60px;
  bottom: 0;
  margin-left: -30px;
  left: 50%;
}
.section-title p {
  font-size: 18px;
}
.btn-custom {
  font-family: "Raleway", sans-serif;
  text-transform: uppercase;
  color: #fff;
  background-color: #5ca9fb;
  background-image: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
  padding: 14px 34px;
  letter-spacing: 1px;
  margin: 0;
  font-size: 15px;
  font-weight: 500;
  border-radius: 25px;
  transition: all 0.5s linear;
  border: 0;
}
.btn-custom:hover,
.btn-custom:focus,
.btn-custom.focus,
.btn-custom:active,
.btn-custom.active {
  color: #fff;
  background-image: none;
  background-color: #6372ff;
}
.btn:active,
.btn.active {
  background-image: none;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
a:focus,
.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  outline: none;
  outline-offset: none;
}

/* Header Section */
#header {
  position: relative;
  height: 100%;
}

.intro {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 0;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.overlay {
  position: absolute;
  background-image: linear-gradient(0deg, #37474fc4, #00695c8f);
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  opacity: 0.4;
}

.carousel-item {
  width: 100%;
}

.carousel-item img {
  height: 100%;
}

#header span.avian {
  color: #e74091;
}

#header span.for {
  color: #fbc02d;
}

#header span.india {
  color: #2986ff;
}

#header h1 {
  font-weight: 800;
  font-size: 5em;
  color: #212222;
  font-family: "Pacifico";
}

#header p {
  color: #fff;
  font-size: 22px;
  font-weight: 500;
  line-height: 30px;
  margin: 0 auto;
  margin-bottom: 60px;
}

@media only screen and (max-width: 600px) {
  #header h1 {
    display: none;
  }

  #header p {
    font-size: 10px;
    line-height: normal;
    margin: 0;
  }
}

.carousel .carousel-inner {
  box-shadow: 0px 0px 0px 0px;
  border-radius: 0;
}

header .intro-text {
  position: absolute;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  z-index: 10;
  color: white;
  height: 100%;
}

.org-logo {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: relative;
  justify-content: center;
  display: flex;
  width: 100%;
  align-items: center;
}

@media only screen and (max-width: 450px) {
  .org-logo img {
    width: 35%;
  }

  .intro h1 {
    font-size: 60px;
  }
}
/* Features Section */
#features {
  padding: 30px 0;
}
#features i.fa {
  font-size: 38px;
  margin-bottom: 20px;
  transition: all 0.5s;
  color: #fff;
  width: 100px;
  height: 100px;
  padding: 30px 0;
  border-radius: 50%;
  background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.05);
}
/* About Section */
#about {
  padding: 20px;
}

#about .about-img img {
  width: 100%;
}
#about h3 {
  font-size: 22px;
  margin: 0 0 20px 0;
}

#about .about-heading {
  text-align: center;
}
/* #about h2 {
	position: relative;
	margin-bottom: 15px;
	padding-bottom: 15px;
} */
/* #about h2::after {
	position: absolute;
	content: "";
	background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
	height: 4px;
	width: 60px;
	bottom: 0;
	left: 0;
} */

#about .about-text li {
  margin-bottom: 6px;
  margin-left: 6px;
  padding: 0;
}
#about .about-text li:before {
  content: "\f00c";
  font-family: "FontAwesome";
  color: #5ca9fb;
  font-size: 11px;
  font-weight: 300;
  padding-right: 8px;
}
#about img {
  width: 520px;
  background: #fff;
  border-right: 0;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.06);
}
#about p {
  line-height: 24px;
  margin: 30px 0;
}

/* Founder */
#founder {
  padding: 30px 0;
  background-color: rgb(241, 241, 241);
}
#founder h3 {
  font-size: 22px;
  margin: 0 0 20px 0;
}
#founder h2 {
  position: relative;
  margin-bottom: 15px;
  padding-bottom: 15px;
}
#founder h2::after {
  position: absolute;
  content: "";
  background: linear-gradient(to right, #5ca9fb 0%, #6372ff 100%);
  height: 4px;
  width: 60px;
  bottom: 0;
  left: 0;
}

#founder .founder-body {
  margin-top: 2rem;
}

.founder-seperator {
  width: 100%;
}
#founder .about-text li {
  margin-bottom: 6px;
  margin-left: 6px;
  padding: 0;
}
#founder .about-text li:before {
  content: "\f00c";
  font-family: "FontAwesome";
  color: #5ca9fb;
  font-size: 11px;
  font-weight: 300;
  padding-right: 8px;
}
#founder img {
  width: 100%;
  margin-top: 10px;
  background: #fff;
  border-right: 0;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.06);
}
#about p {
  line-height: 24px;
  margin: 30px 0;
}
/* Services Section */
#services {
  padding: 30px 0;
  background: linear-gradient(to right, #9c1c5a 0%, #e74091 100%);
  color: #fff;
}
#services .service-desc {
  margin: 10px 10px 20px;
}
#services h2 {
  color: #fff;
}
#services .section-title h2::after {
  position: absolute;
  content: "";
  background: rgba(255, 255, 255, 0.3);
  height: 4px;
  width: 60px;
  bottom: 0;
  margin-left: -30px;
  left: 50%;
}
#services i.fa {
  font-size: 42px;
  width: 120px;
  height: 120px;
  padding: 40px 0;
  background: linear-gradient(to right, #6372ff 0%, #5ca9fb 100%);
  border-radius: 50%;
  color: #fff;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.05);
}
#services h3 {
  font-weight: 500;
  padding: 5px 0;
  color: #fff;
}
#services p {
  color: rgba(255, 255, 255, 0.75);
}
#services .service-desc {
  margin-bottom: 40px;
}
/* Portfolio Section */
#portfolio {
  padding: 30px 0;
}


.portfolio-item {
  padding: 0;
}
.portfolio-item .hover-bg {
  overflow: hidden;
  position: relative;
  margin: 0;
}
.portfolio-item .hover-bg img {
  max-width: 100%;
  padding: 0;
}
.portfolio-item .hover-bg a {
  width: 100%;
}
.hover-bg .hover-text {
  position: absolute;
  text-align: center;
  margin: 0 auto;
  color: #fff;
  background: linear-gradient(
    to right,
    rgba(99, 114, 255, 0.8) 0%,
    rgba(92, 169, 251, 0.8) 100%
  );
  padding: 30% 0 0 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  z-index: 1;
  transition: all 0.5s;
}
.hover-bg .hover-text > h4 {
  opacity: 0;
  color: #fff;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  transition: all 0.3s;
  font-size: 18px;
  letter-spacing: 1px;
  font-weight: 500;
  text-transform: uppercase;
}
.hover-bg:hover .hover-text > h4 {
  opacity: 1;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
.hover-bg:hover .hover-text {
  opacity: 1;
}
/* Testimonials Section */
#testimonials {
  padding: 100px 0;
  background: linear-gradient(to right, #9c1c5a 0%, #e74091 100%);

  .gallery-images {
    height: 1000px;
  }
}
#testimonials i {
  color: #e6e6e6;
  font-size: 32px;
  margin-bottom: 20px;
}
.testimonial {
  position: relative;
  padding: 20px;
}
.testimonial-image {
  float: left;
  margin-right: 15px;
}
.testimonial-image,
.testimonial-image img {
  display: block;
  width: 64px;
  height: 64px;
  border-radius: 50%;
}
.testimonial-content {
  position: relative;
  overflow: hidden;
}
.testimonial-content p {
  margin-bottom: 0;
  font-size: 14px;
  font-style: italic;
  color: #ffffff;
}
.testimonial-meta {
  margin-top: 10px;
  font-size: 15px;
  font-weight: 600;
  color: #ffd11f;
}
/* Team Section */
.team {
  width: 100%;
  background-color: #f1f1f1;
}
.team {
  padding: 50px 20px;
}
.team h4 {
  margin: 5px 0;
}
.team .team-img {
  width: 240px;
}
.team .thumbnail {
  background: transparent;
  border: 0;
}
.team .thumbnail .caption {
  padding: 10px 0 0 0;
  color: #888;
}


/* faq */

#faq {
  padding: 35px;
}

/* PayOptions */
.pay-options {
  padding: 70px 0;
}

.jumbotron {
  background-color: #dcedc8;
}

.display-3 {
  color: #388e3c;
  font-weight: 500;
}
@media (max-width: 768px) {
  .display-3 {
    font-size: 2rem;
  }
}
.lead {
  background-color: #dfffe7;
}
/* Contact Section */
#contact {
  padding: 1rem;
  background: linear-gradient(to right, #8216a8 0%, #600fbf 100%);
  color: rgba(255, 255, 255, 0.75);
}
#contact .section-title {
  margin-bottom: 40px;
}
#contact .section-title p {
  font-size: 16px;
}
#contact h2 {
  color: #fff;
  margin-top: 10px;
  margin-bottom: 15px;
  padding-bottom: 15px;
}
#contact .section-title h2::after {
  position: absolute;
  content: "";
  background: rgba(255, 255, 255, 0.3);
  height: 4px;
  width: 60px;
  bottom: 0;
  left: 30px;
}
.contact-info h3 {
  color: #fff;
  margin-top: 80px;
  margin-bottom: 25px;
  font-weight: 400;
  border-bottom: solid;
  border-bottom-width: thin;
}
#contact form {
  padding-top: 20px;
}
#contact .text-danger {
  color: #cc0033;
  text-align: left;
}
#contact .btn-custom {
  margin: 30px 0;
  background: transparent;
  border: 2px solid #fff;
}
#contact .btn-custom:hover {
  color: #1f386e;
  background: #fff;
}
label {
  font-size: 12px;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
  float: left;
}
#contact .form-control {
  display: block;
  width: 100%;
  padding: 6px 12px;
  font-size: 16px;
  line-height: 1.42857143;
  color: #444;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ddd;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
}
#contact .form-control:focus {
  border-color: #999;
  outline: 0;
  -webkit-box-shadow: transparent;
  box-shadow: transparent;
}
.form-control::-webkit-input-placeholder {
  color: #777;
}
.form-control:-moz-placeholder {
  color: #777;
}
.form-control::-moz-placeholder {
  color: #777;
}
.form-control:-ms-input-placeholder {
  color: #777;
}
#contact .contact-item {
  margin: 20px 0;
}
#contact .contact-item span {
  color: rgba(255, 255, 255, 1);
  margin-bottom: 10px;
  display: block;
}
#contact .contact-item i.fa {
  margin-right: 10px;
}
#contact .social {
  text-align: center;
  padding: 35px 0;
  width: 100%;
}

#contact .social h3 {
  writing-mode: vertical-lr;
  text-align: center;
  color: #ffffff;
  border-left: solid thin;
}

#contact .social ul li {
  display: inline-block;
  margin: 20px 0px;
}
#contact .social i.fa {
  font-size: 22px;
  width: 48px;
  height: 48px;
  padding: 12px 0;
  border: 2px solid #fff;
  color: #fff;
  border-radius: 50%;
  transition: all 0.3s;
}

#contact .social i.fa:hover {
  color: #608dfd;
  background: #fff;
}

/* Footer Section*/
.social {
  writing-mode: vertical-rl;
}

@media only screen and (max-width: 600px) {
  .social {
    writing-mode: horizontal-tb;
  }

  #contact .social h3 {
    writing-mode: horizontal-tb;
    border-bottom: solid thin;
    border-left: none;
  }
  #contact .social ul li {
    margin: 20px;
  }
}

.social i {
  margin: 10px 0;
}

.footer {
  background: #f6f6f6;
}
.footer p {
  color: #888;
  font-size: 14px;
}
.footer a {
  color: #608dfd;
}
.footer a:hover {
  border-bottom: 2px solid #608dfd;
}

.footer .copyright {
  text-align: left;
}

/* Donate */
button.amt-opt {
  width: max-content;
}
input#amount {
  width: 300px;
  background: white;
  margin: 20px 0 0 0;
  font-size: 15px;
  padding: 10px;
}

.gallery_card {
  margin: 20px 0;
}

div.gallery_card > h3 {
  border: solid;
  border-top: none;
  border-left: none;
  border-right: none;
  width: max-content;
}
@media only screen and (max-width: 600px) {
  input#amount {
    width: 100%;
  } 
  .footer .copyright {
    text-align: center!important;
  }
}
/* Privacy Policy */
.container_section {
  padding: 40px 0 40px 0;
  width: 100%;
  padding: 10% 5%;
}

/*-----------------
Tables 
------------------- */

table {
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}
