

#collab {
    padding: 3rem 0;

    .collab_icons {
        margin: 3rem 0;
    }
    img {
        width: 100%;
    }
}