.index-wrapper {
  #about {
    img {
      padding: 1rem;
      transform: rotate(-3deg);
    }
  }
  #founder {
    img {
      padding: 1rem;
      transform: rotate(3deg);
    }
  }
  #about,
  #founder {
    @media only screen and (max-width: 600px) {
      img {
        padding: 1rem;
      }
    }
  }
}
