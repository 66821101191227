@import url('https://fonts.googleapis.com/css2?family=Cantarell:ital@1&display=swap');
.tim-row {
  margin-bottom: 20px;
}

.tim-white-buttons {
  background-color: #777777;
}

.typography-line {
  padding-left: 25%;
  margin-bottom: 35px;
  position: relative;
  display: block;
  width: 100%;
}

.pick-class-label {
  border-radius: 8px;
  border: 1px solid #e3e3e3;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  margin-right: 10px;
  padding: 23px;
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap;
}

.offline-doc .page-header {
  display: flex;
  align-items: center;
}

.index-page .page-header {
  height: 100vh;
}

div#carousel {
  position: absolute;
}

.text-center {
  width: 100%;
}
.impact-info-img {
  width: 100px;
}
.typography-line span {
  bottom: 10px;
  color: #c0c1c2;
  display: block;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
  left: 0;
  margin-left: 20px;
  position: absolute;
  width: 260px;
  text-transform: none;
}

.tim-row {
  padding-top: 60px;
}

.tim-row h3 {
  margin-top: 0;
}

.switch {
  margin-right: 20px;
}

#navbar-full .navbar {
  border-radius: 0 !important;
  margin-bottom: 15px;
  z-index: 2;
}

#menu-dropdown .navbar {
  border-radius: 3px;
}

#pagination-row .pagination-container {
  height: 100%;
  max-height: 100%;
  display: flex;
  align-items: center;
}

#icons-row i.now-ui-icons {
  font-size: 30px;
}

.space {
  height: 130px;
  display: block;
}

.space-110 {
  height: 110px;
  display: block;
}

.space-50 {
  height: 50px;
  display: block;
}

.space-70 {
  height: 70px;
  display: block;
}

.navigation-example .img-src {
  background-attachment: scroll;
}

.navigation-example {
  background-position: center center;
  background-size: cover;
  margin-top: 0;
  min-height: 740px;
  height: 100%;
}

#notifications {
  background-color: #ffffff;
  display: block;
  width: 100%;
  position: relative;
}

.tim-note {
  text-transform: capitalize;
}

#buttons .btn,
#javascriptComponents .btn {
  margin: 0 0px 10px;
}

.space-100 {
  height: 100px;
  display: block;
  width: 100%;
}

.be-social {
  padding-bottom: 20px;
  /*     border-bottom: 1px solid #aaa; */
  margin: 0 auto 40px;
}

.txt-white {
  color: #ffffff;
}

.txt-gray {
  color: #ddd !important;
}

.parallax {
  width: 100%;
  height: 570px;
  display: block;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.logo-container .logo {
  overflow: hidden;
  border-radius: 50%;
  border: 1px solid #333333;
  width: 50px;
  float: left;
}

.logo-container .brand {
  font-size: 16px;
  color: #ffffff;
  line-height: 18px;
  float: left;
  margin-left: 10px;
  margin-top: 7px;
  width: 70px;
  height: 40px;
  text-align: left;
}

.content-center {
  position: absolute;
  text-align: center;
  color: white;
  z-index: 20;
  width: 100%;
  top: 30%;
}

.logo-container .brand-material {
  font-size: 18px;
  margin-top: 15px;
  height: 25px;
  width: auto;
}

.logo-container .logo img {
  width: 100%;
}

.navbar-small .logo-container .brand {
  color: #333333;
}

.fixed-section {
  top: 90px;
  max-height: 80vh;
  overflow: scroll;
  position: sticky;
}

.fixed-section ul {
  padding: 0;
}

/* .fixed-section ul li {
  list-style: none;
} */

.fixed-section li a {
  font-size: 14px;
  padding: 2px;
  display: block;
  color: #666666;
}

.fixed-section li a.active {
  color: #00bbff;
}

.fixed-section.float {
  position: fixed;
  top: 100px;
  width: 200px;
  margin-top: 0;
}

.parallax .parallax-image {
  width: 100%;
  overflow: hidden;
  position: absolute;
}

.parallax .parallax-image img {
  width: 100%;
}

@media (max-width: 768px) {
  .parallax .parallax-image {
    width: 100%;
    height: 640px;
    overflow: hidden;
  }
  .parallax .parallax-image img {
    height: 100%;
    width: auto;
  }
}

.social-buttons-demo .btn {
  margin-right: 5px;
  margin-bottom: 7px;
}

.img-container {
  width: 100%;
  overflow: hidden;
}

.img-container img {
  width: 100%;
}

.lightbox img {
  width: 100%;
}

.lightbox .modal-content {
  overflow: hidden;
}

.lightbox .modal-body {
  padding: 0;
}

@media screen and (min-width: 991px) {
  .lightbox .modal-dialog {
    width: 960px;
  }
}

@media (max-width: 991px) {
  .fixed-section.affix {
    position: relative;
    margin-bottom: 100px;
  }
}

@media (max-width: 768px) {
  .btn,
  .btn-morphing {
    margin-bottom: 10px;
  }
  .parallax .motto {
    top: 170px;
    margin-top: 0;
    font-size: 60px;
    width: 270px;
  }
}

.presentation .front,
.presentation .front:after,
.presentation .front .btn,
.logo-container .logo,
.logo-container .brand {
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

#images h4 {
  margin-bottom: 30px;
}

#javascriptComponents {
  padding-bottom: 0;
}

/*      layer animation          */

.layers-container {
  display: block;
  margin-top: 50px;
  position: relative;
}

.layers-container img {
  position: absolute;
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
  text-align: center;
}

.animate {
  transition: 1.5s ease-in-out;
  -moz-transition: 1.5s ease-in-out;
  -webkit-transition: 1.5s ease-in-out;
}

.navbar-default.navbar-small .logo-container .brand {
  color: #333333;
}

.navbar-transparent.navbar-small .logo-container .brand {
  color: #ffffff;
}

.navbar-default.navbar-small .logo-container .brand {
  color: #333333;
}

.sharing-area {
  margin-top: 80px;
}

.sharing-area .btn {
  margin: 15px 4px 0;
}

.section-thin,
.section-notifications {
  padding: 0;
}

.section-navbars {
  padding-top: 0;
}

#navbar .navbar {
  margin-bottom: 20px;
}

#navbar .navbar-toggler,
#menu-dropdown .navbar-toggler {
  pointer-events: none;
}

.nav-item .active {
  text-align: center;
  text-decoration: none;
  border-bottom: 2px solid #e74091;
  color: #e74091 !important;
}

.social-link {
  margin : 0 5px;
}

.navbar-light .navbar-toggler {
  border-color: transparent !important;
}

.section-tabs {
  background: #eeeeee;
}

.section-pagination {
  padding-bottom: 0;
}

.section-download {
  padding-top: 130px;
}

.section-download .description {
  margin-bottom: 60px;
}

.section-download h4 {
  margin-bottom: 25px;
}

.section-examples a {
  text-decoration: none;
}

.section-examples a + a {
  margin-top: 30px;
}

.section-examples h5 {
  margin-top: 30px;
}

.components-page .wrapper > .header,
.tutorial-page .wrapper > .header {
  height: 500px;
  padding-top: 128px;
  background-size: cover;
  background-position: center center;
}

.carousel-overlay {
  background: linear-gradient(180deg, #0089ff, transparent);
  color: black;
  min-height: 100vh;
  position: absolute;
  width: 100%;
  z-index: 11;
  opacity: 0.8;
}

.components-page .title,
.tutorial-page .title {
  color: #ffffff;
}

.brand .h1-seo {
  font-size: 2.8em;
  text-transform: uppercase;
  font-weight: 300;
}

.brand .n-logo {
  max-width: 100px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.invision-logo {
  max-width: 70px;
  top: -2px;
  position: relative;
}

.creative-tim-logo {
  max-width: 140px;
  top: -2px;
  position: relative;
}

.section-javascript .title {
  margin-bottom: 0;
}

.navbar .switch-background {
  display: block;
}

.navbar-transparent .switch-background {
  display: none;
}

.section-signup .col .btn {
  margin-top: 30px;
}

#buttons-row .btn {
  margin-bottom: 10px;
}

.section-basic {
  padding-top: 0;
}

.section-images {
  padding-bottom: 0;
}

.site-menu {
  display: block;
}

img.image-hover {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  border-radius: 20px;
}

.alert {
  border-radius: 5px;
}
img.image-hover:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  box-shadow: 0px 5px 15px 5px #cacaca;
}

i.c-work-odd-icon {
  font-size: 30px;
  margin: 5px;
  border: solid;
  border-radius: 50%;
  padding: 15px;
  color: #f96332;
}

i.c-work-even-icon {
  font-size: 30px;
  margin: 5px;
  border: solid;
  border-radius: 50%;
  padding: 15px;
  color: #2ca8ff;
}

.container-box {
  border: solid;
  border-radius: 5px;
  border-color: #e2e2e2;
  height: 70vh;
  max-height: fit-content;
  padding: 0 10px 20px 10px;
}

.react-scrollable-list {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  height: 300px;
  width: 100%;
  border: rgb(190, 190, 190) lid 1px;
}

.react-scrollable-list-item {
  padding: 2rem;
  vertical-align: middle;
  margin: 9px 0 9px 0;
  border-radius: 5px;
  background-color: #2ca8ff;
  border-bottom: #007bff solid 4px;
  color: #ffffff;
  font-weight: bold;
  pointer-events: none;
}

.list-item {
  border-bottom: #727bf3 solid 5px;
  margin: 5px 0 5px 0;
}

.menu-item {
  padding: 0 40px;
  margin: 5px 10px;
  user-select: none;
  cursor: pointer;
  border: none;
}
.menu-item-wrapper.active {
  border: 1px blue solid;
}
.menu-item.active {
  border: 1px green solid;
}

.scroll-menu-arrow {
  padding: 20px;
  cursor: pointer;
}

.hero-img {
  padding: 30px;
}

.event-card {
  padding: 30px;
}

.testimonial-card {
  margin: 0 20px 0 20px;
}

.testimonial-card-inner {
  margin: 10px 0 10px 0;
  padding: 15px;
}

img.testimonial-img {
  width: 100px;
  height: 100px;
  margin-top: -75px;
  border-radius: 50%;
}

textarea.form-control {
  border-radius: 0 30px 30px 0;
}

.card-signup {
  max-width: 550px;
}
.react-multi-carousel-list {
  padding: 50px 0 0 0;
}

.partner-content-center {
  text-align: center;
}

p.nav-menu-text {
  font-size: 11px;
  font-weight: bold;
}

@media screen and (max-width: 991px) {
  .section-navbars .navbar-collapse {
    display: none !important;
  }
}
